import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

export default () => (
  <Layout>
    <SEO title="About" />

    <div id="wrapper">
      <div>
        <h1>About</h1>
        <div style={{ maxWidth: 600, margin: 'auto' }}>
          <p style={{ textAlign: 'center' }}>Yankee is a private company founded by Mikael Finstad in 2009 delivering software development services.</p>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <h2>Contact</h2>
              <div>Mikael Finstad</div>
              <div>(+47) 95 99 95 17</div>
              <div><a href="mailto:mikael@yankee.no">mikael@yankee.no</a></div>
              <div>Skype: mikaelfinstad</div>

              <h2>Address</h2>
              Mikael Finstad<br />
              Calmeyers gate 4<br />
              0183 Oslo<br />
              Norway
            </div>

            <div>
              <h2>Company details</h2>
              <div>Name: Yankee Finstad</div>
              <div>Org. no.: NO <b>994346857</b> MVA</div>
              <div><a target="_blank" rel="noopener noreferrer" href="https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=994346857">Brønnøysund registry</a></div>
              <h2>Logo</h2>
              <p><img src="/images/yankee.png" alt="Yankee logo" style={{ height: 64 }} /></p>
              <p><a href="/images/yankee.png">Download PNG</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
